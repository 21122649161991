import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Post from '../templates/Post';
import Meta from '../components/Meta';
import Layout from '../components/Layout';
import Interstitial from '../components/Interstitial';

const WhatsAppWearealleuropeans = ({ data, location }) => (
  <Layout location={location}>
    <Meta
      title="Save our future! Vote in the EU elections - here's how 🗳"
      description="Click the link and send a message to friends on WhatsApp - give them a voice! ➡️"
      shareImage="wearealleuropeans"
      location={location}
      site={get(data, 'site.meta')}
      whatsAppMessage={`Hey! Are you an EU national living in the UK?
We have the right to vote in the EU elections in the UK!

Send this message to your European friends in the UK - this is our home too!
Click here: https://saveourfuture.uk/wearealleuropeans

*Register to vote by May 7th*
Follow the simple steps here:
https://saveourfuture.uk/simple

(Do it now!)

*IMPORTANT !!!*
💥 Send this message to your European friends in the UK - this is our home too! 💥
Click here: https://saveourfuture.uk/wearealleuropeans

🗣 *THIS TIME OUR VOICE COUNTS!!!* 🗣`}
    />
    <Interstitial />
  </Layout>
);

WhatsAppWearealleuropeans.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WhatsAppWearealleuropeans;

export const query = graphql`
  query WhatsAppWearealleuropeansQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
  }
`;
